<template>
<div class="visit-chart-page">
  <div class="visit-chat-nav">
    <div :class="selected === '1' ? 'chart-nav-item active' : 'chart-nav-item'" @click="selected = '1'">
      眼轴
    </div>
    <div :class="selected === '2' ? 'chart-nav-item active' : 'chart-nav-item'" @click="selected = '2'" >
      屈光度
    </div>
    <div :class="selected === '3' ? 'chart-nav-item active' : 'chart-nav-item'" @click="selected = '3'" >
      矫正视力
    </div>
  </div>

  <!-- tab-container -->
  <div>
    <div v-if="selected === '1'">
      <div class="visit-table">
        <table border="1">
          <tbody>
            <tr>
                <td>最近测试时间</td>
                <td>左眼</td>
                <td>右眼</td>
            </tr>
            <tr>
                <td>{{getDate(visit.createdAt)}}</td>
                <td>{{visit.axis_os || '无'}}</td>
                <td>{{visit.axis_od || '无'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="visit-chart">
        <h3>眼轴对比曲线</h3>
        <ve-line :extend="chartExtend" width="100%" height="300px" :data="axisChartData"></ve-line>
      </div>
    </div>
    <div v-if="selected === '2'">
      <div class="visit-table">
        <table border="1">
          <tbody>
            <tr>
                <td>最近测试时间</td>
                <td>左眼</td>
                <td>右眼</td>
            </tr>
            <tr>
                <td>{{getDate(visit.createdAt)}}</td>
                <td>{{visit.diopter_os_sph || '无'}}/{{visit.diopter_os_cyl || '无'}}/{{visit.diopter_os_axis || '无'}}</td>
                <td>{{visit.diopter_od_sph || '无'}}/{{visit.diopter_od_cyl || '无'}}/{{visit.diopter_od_axis || '无'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="visit-chart">
        <h3>屈光度（球镜）对比曲线</h3>
        <ve-line :extend="chartExtend" width="100%" height="300px" :data="diopter_0_ChartData"></ve-line>
      </div>
      <div class="visit-chart">
        <h3>屈光度（柱镜）对比曲线</h3>
        <ve-line :extend="chartExtend" width="100%" height="300px" :data="diopter_1_ChartData"></ve-line>
      </div>
      <!-- <div class="visit-chart">
        <h3>屈光度（轴位）对比曲线</h3>
        <ve-line :extend="chartExtend" width="100%" height="300px" :data="diopter_2_ChartData"></ve-line>
      </div> -->
    </div>
    <div v-if="selected === '3'">
      <div class="visit-table">
        <table border="1">
          <tbody>
            <tr>
                <td>最近测试时间</td>
                <td>左眼</td>
                <td>右眼</td>
            </tr>
            <tr>
                <td>{{getDate(visit.createdAt)}}</td>
                <td>{{visit.vision_os || '无'}}</td>
                <td>{{visit.vision_od || '无'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="visit-chart">
        <h3>矫正视力对比曲线</h3>
        <ve-line :extend="chartExtend" width="100%" height="300px" :data="visionChartData"></ve-line>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import VeLine from 'v-charts/lib/line.common'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'VisitList',
  components: { VeLine },
  data() {
    return {
      selected: '1',
      visit: {},
      chartExtend: {
        color: ['red', 'blue'],
        yAxis: {
          min: 'dataMin'
        }
      },
      axisChartData: {
        columns: ['次', '左眼', '右眼'],
        rows: [
        ]
      },
      visionChartData: {
        columns: ['次', '左眼', '右眼'],
        rows: [
        ]
      },
      diopter_0_ChartData: {
        columns: ['次', '左眼', '右眼'],
        rows: [
        ]
      },
      diopter_1_ChartData: {
        columns: ['次', '左眼', '右眼'],
        rows: [
        ]
      },
      diopter_2_ChartData: {
        columns: ['次', '左眼', '右眼'],
        rows: [
        ]
      }
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
    this.getVisits()
  },
  methods: {
    getDate (createdAt) {
      if (!createdAt) {
        return ''
      }
      return moment(createdAt).format('YY/MM/DD')
    },
    getVisits () {
      this.$store.httpDispatch({})('getVisits')
      .then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          const list = this.list = res.data.data.visits
          this.visit = res.data.data.visits[res.data.data.visits.length - 1] || {}
          let axisChartData = Object.assign({}, this.axisChartData)
          let visionChartData = Object.assign({}, this.visionChartData)
          let diopter_0_ChartData = Object.assign({}, this.diopter_0_ChartData)
          let diopter_1_ChartData = Object.assign({}, this.diopter_1_ChartData)
          let diopter_2_ChartData = Object.assign({}, this.diopter_2_ChartData)
          axisChartData.rows = list.map((visit, index) => {
            return {
              '次': this.getDate(visit.recordedAt),
              '左眼': visit.axis_os,
              '右眼': visit.axis_od
            }
          })
          visionChartData.rows = list.map((visit, index) => {
            return {
              '次': this.getDate(visit.recordedAt),
              '左眼': visit.vision_os,
              '右眼': visit.vision_od
            }
          })

          diopter_0_ChartData.rows = list.map((visit, index) => {
            return {
              '次': this.getDate(visit.recordedAt),
              '左眼': visit.diopter_os_sph,
              '右眼': visit.diopter_od_sph,
            }
          })
          diopter_1_ChartData.rows = list.map((visit, index) => {
            return {
              '次': this.getDate(visit.recordedAt),
              '左眼': visit.diopter_os_cyl,
              '右眼': visit.diopter_od_cyl
            }
          })
          diopter_2_ChartData.rows = list.map((visit, index) => {
            return {
              '次': this.getDate(visit.recordedAt),
              '左眼': visit.diopter_os_axis,
              '右眼': visit.diopter_od_axis
            }
          })
          console.log(axisChartData)
          this.axisChartData = axisChartData
          this.visionChartData = visionChartData
          this.diopter_0_ChartData = diopter_0_ChartData
          this.diopter_1_ChartData = diopter_1_ChartData
          this.diopter_2_ChartData = diopter_2_ChartData
        }
      })
    }
  }
}
</script>

<style lang="less">
.visit-chart-page {
  box-sizing: border-box;
  padding: 50px 20px 50px;
  .mint-navbar {
    .mint-tab-item {

    }
  }
  .visit-chat-nav {
    display: flex;
    width: 100%;
    .chart-nav-item {
      background: #B0B0B0;
      border-radius: 5px;
      height: 30px;
      line-height: 30px;
      font-size: 13px;
    }
    .active {
      background: #007FC2;
      color: #fff
    }
    div {
      flex: 1;
      margin-right: 10px;
      img {
        width: 100%;
      }
    }
    div:last-child {
      margin-right: 0;
    }
  }
  .visit-chart {
    background: #fff;
    height: 300px;
    border-radius: 20px;
    padding: 10px 20px;
    margin-top: 20px;
  }
  .visit-table {
    background: #B2B2B2;
    border-radius: 8px;
    margin-top: 10px;
    table {
      width: 100%;
      border-radius: 10px;
      border-collapse: collapse;
      font-family: "黑体";
      font-weight: 800;
      tr {
        height: 30px;
        td {
          width: 33%;
        }
      }
    }
    table, th, td { border: 1px solid #D0D0D0; }
  }
}
</style>
