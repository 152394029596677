<template>
  <div class="visit-list" >
    <div>
      <div style="text-align: right;color: #007EC5;font-weight: 800">剩余积分: {{user.points}}</div>
    </div>
    <div class="visit-titile">
      <div style="flex: 1;margin-right: 10px;">日期</div>
      <div style="flex: 1;margin-right: 10px;">积分变化</div>
      <div style="flex: 1">余额</div>
    </div>
    <div @click="activeIndex = index" class="visit-item" v-for="(record, index) in list" :key="record._id">
      <div class="visit-head">
        <div style="flex: 1">
          <span>{{getDate(record.createdAt)}}</span>
        </div>
        <div style="flex: 1">
          <span>{{record.operate === 'add' ? '+' : '-'}}{{record.points}}</span>
        </div>
        <div style="flex: 1">
          <span>{{record.total}}</span>
          <img v-if="activeIndex === index" class="icon-bottom" src="../../assets/icon_bottom.png" alt="">
          <img v-else class="icon-right" src="../../assets/icon_right.png" alt="">
        </div>
      </div>
      <div class="visit-table" v-if="activeIndex === index">
        <table border="1">
          <tbody>
            <tr>
              <td>详情：{{record.operate === 'add' ? '+' : '-'}}{{record.points}} | {{record.remark}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <mt-button class="visit-btn" @click="$router.push('/')" type="primary">返回</mt-button>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'VisitList',
  data() {
    return {
      activeIndex: null,
      list: []
    }
  },
  components: {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
    this.getUserPointsRecord()
  },
  methods: {
    getDate (createdAt) {
      return moment(createdAt).format('YYYY/MM/DD')
    },
    getTime (createdAt) {
      return moment(createdAt).format('YYYY/MM/DD HH:mm')
    },
    getUserPointsRecord () {
      this.$store.httpDispatch({})('getUserPointsRecord')
      .then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.list = res.data.data.records
        }
      })
    }
  }
}
</script>

<style lang="less">
.visit-list {
  padding-top: 50px;
  padding: 50px 20px 0;
  box-sizing: border-box;
  .visit-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    left: 50%;
    margin-left: -40vw;
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
  }
  .visit-titile {
    background: none;
    width: 100%;
    display: flex;
    height: 35px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 10px;
    font-size: 15px;
    div {
      background: #007EC5;
      border-radius: 5px;
    }
  }
  .visit-item {
    margin-bottom: 10px;
    .visit-table {
      background: #B2B2B2;
      border-radius: 8px;
      margin-top: 10px;
      table {
        width: 100%;
        border-radius: 10px;
        border-collapse: collapse;
        font-family: "黑体";
        font-weight: 800;
        tr {
          height: 30px;
          td:first-child {
            width: 80px;
          }
          td:nth-child(1) {
            width: 80px;
          }
        }
      }
      table, th, td { border: 1px solid #D0D0D0; word-break: break-all; text-align: left; padding-left: 20px; }
    }
  }
  .visit-head {
    position: relative;
    width: 100%;
    background: #fff;
    display: flex;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    font-family: "黑体";
    font-weight: 800;
    .headerDivider {
      box-sizing: border-box;
      border-left:1px solid #ADD6E9;
      border-right:1px solid #ADD6E9;
      height: 18px;
      position:absolute;
      left: 60px;
      top: 8.5px;
    }
    .icon-right {
      height: 18px;
      position: absolute;
      right: 20px;
      top: 8.5px;
    }
    .icon-bottom {
      width: 18px;
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}
</style>
